/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const sequenceIncrement = /* GraphQL */ `
  mutation SequenceIncrement($Name: String!) {
    sequenceIncrement(Name: $Name) {
      Name
      CurrentNumber
    }
  }
`;
export const shorteningUrl = /* GraphQL */ `
  mutation ShorteningUrl($urlLong: String) {
    shorteningUrl(urlLong: $urlLong)
  }
`;
export const updateResponseSettings = /* GraphQL */ `
  mutation UpdateResponseSettings(
    $facilityId: String
    $settingList: String
    $fetchedList: String
  ) {
    updateResponseSettings(
      facilityId: $facilityId
      settingList: $settingList
      fetchedList: $fetchedList
    )
  }
`;
export const updateResponseSettingsTemplate = /* GraphQL */ `
  mutation UpdateResponseSettingsTemplate(
    $menuName: String
    $settingList: String
    $fetchedList: String
  ) {
    updateResponseSettingsTemplate(
      menuName: $menuName
      settingList: $settingList
      fetchedList: $fetchedList
    )
  }
`;
export const updateTimeSettings = /* GraphQL */ `
  mutation UpdateTimeSettings($facilityId: String, $receptionData: String) {
    updateTimeSettings(facilityId: $facilityId, receptionData: $receptionData)
  }
`;
export const updateTransferTargetPattern = /* GraphQL */ `
  mutation UpdateTransferTargetPattern(
    $facilityId: String
    $transferTargetPatternList: String
  ) {
    updateTransferTargetPattern(
      facilityId: $facilityId
      transferTargetPatternList: $transferTargetPatternList
    )
  }
`;
export const sendSMSWithSMSAPI = /* GraphQL */ `
  mutation SendSMSWithSMSAPI(
    $hospitalGuid: String
    $facilityId: Int
    $phoneNumber: String
    $text: String
    $contactId: String
  ) {
    sendSMSWithSMSAPI(
      hospitalGuid: $hospitalGuid
      facilityId: $facilityId
      phoneNumber: $phoneNumber
      text: $text
      contactId: $contactId
    )
  }
`;
export const setUpCcp = /* GraphQL */ `
  mutation SetUpCcp(
    $facilityId: Int
    $facilityName: String
    $connectedPhoneNumber: String
    $calciumUserId: String
    $userId: String
  ) {
    setUpCcp(
      facilityId: $facilityId
      facilityName: $facilityName
      connectedPhoneNumber: $connectedPhoneNumber
      calciumUserId: $calciumUserId
      userId: $userId
    )
  }
`;
export const updateConnectUserMail = /* GraphQL */ `
  mutation UpdateConnectUserMail($facilityId: Int, $mailAddress: String) {
    updateConnectUserMail(facilityId: $facilityId, mailAddress: $mailAddress)
  }
`;
export const batchAddMivr = /* GraphQL */ `
  mutation BatchAddMivr($items: [CreateMivrInput]) {
    batchAddMivr(items: $items) {
      ID
      AttributeType
      AttributeValue
      DataType
      FacilityID
      Timestamp
      Text
      PhoneNumber
      Status
      YearDate
      HospitalGuid
      MultiAddresses
    }
  }
`;
export const batchDeleteMivr = /* GraphQL */ `
  mutation BatchDeleteMivr($items: [DeleteMivrInput]) {
    batchDeleteMivr(items: $items) {
      ID
      AttributeType
    }
  }
`;
export const transactAddMivr = /* GraphQL */ `
  mutation TransactAddMivr($items: [CreateMivrInput]) {
    transactAddMivr(items: $items) {
      ID
      AttributeType
    }
  }
`;
export const verifyPhoneSuffix = /* GraphQL */ `
  mutation VerifyPhoneSuffix($smsHistoryId: String!, $phoneSuffix: String!) {
    verifyPhoneSuffix(smsHistoryId: $smsHistoryId, phoneSuffix: $phoneSuffix) {
      isVerified
      statusCode
      message
    }
  }
`;
export const createMivr = /* GraphQL */ `
  mutation CreateMivr(
    $input: CreateMivrInput!
    $condition: ModelMivrConditionInput
  ) {
    createMivr(input: $input, condition: $condition) {
      ID
      AttributeType
      AttributeValue
      DataType
      FacilityID
      Timestamp
      Text
      PhoneNumber
      Status
      YearDate
      HospitalGuid
      MultiAddresses
    }
  }
`;
export const updateMivr = /* GraphQL */ `
  mutation UpdateMivr(
    $input: UpdateMivrInput!
    $condition: ModelMivrConditionInput
  ) {
    updateMivr(input: $input, condition: $condition) {
      ID
      AttributeType
      AttributeValue
      DataType
      FacilityID
      Timestamp
      Text
      PhoneNumber
      Status
      YearDate
      HospitalGuid
      MultiAddresses
    }
  }
`;
export const deleteMivr = /* GraphQL */ `
  mutation DeleteMivr(
    $input: DeleteMivrInput!
    $condition: ModelMivrConditionInput
  ) {
    deleteMivr(input: $input, condition: $condition) {
      ID
      AttributeType
      AttributeValue
      DataType
      FacilityID
      Timestamp
      Text
      PhoneNumber
      Status
      YearDate
      HospitalGuid
      MultiAddresses
    }
  }
`;
export const createMivrResponse = /* GraphQL */ `
  mutation CreateMivrResponse(
    $input: CreateMivrResponseInput!
    $condition: ModelMivrResponseConditionInput
  ) {
    createMivrResponse(input: $input, condition: $condition) {
      ContactID
      Timestamp
      DataType
      FacilityID
      CustomerPhoneNumber
      TransferTarget
      CustomerName
      ResponseMenu
      LastResponseMenu
      Message
      ResponseTime
      ConnectionTime
      TransferTime
      SMSSuccessCount
      SMSFailCount
      Transferred
      Status
      Memo
      CheckFlag
      AudioFileUrl
      TranscribeText
      GptSummary
      SMSMessages
    }
  }
`;
export const updateMivrResponse = /* GraphQL */ `
  mutation UpdateMivrResponse(
    $input: UpdateMivrResponseInput!
    $condition: ModelMivrResponseConditionInput
  ) {
    updateMivrResponse(input: $input, condition: $condition) {
      ContactID
      Timestamp
      DataType
      FacilityID
      CustomerPhoneNumber
      TransferTarget
      CustomerName
      ResponseMenu
      LastResponseMenu
      Message
      ResponseTime
      ConnectionTime
      TransferTime
      SMSSuccessCount
      SMSFailCount
      Transferred
      Status
      Memo
      CheckFlag
      AudioFileUrl
      TranscribeText
      GptSummary
      SMSMessages
    }
  }
`;
export const deleteMivrResponse = /* GraphQL */ `
  mutation DeleteMivrResponse(
    $input: DeleteMivrResponseInput!
    $condition: ModelMivrResponseConditionInput
  ) {
    deleteMivrResponse(input: $input, condition: $condition) {
      ContactID
      Timestamp
      DataType
      FacilityID
      CustomerPhoneNumber
      TransferTarget
      CustomerName
      ResponseMenu
      LastResponseMenu
      Message
      ResponseTime
      ConnectionTime
      TransferTime
      SMSSuccessCount
      SMSFailCount
      Transferred
      Status
      Memo
      CheckFlag
      AudioFileUrl
      TranscribeText
      GptSummary
      SMSMessages
    }
  }
`;
export const createMivrMonthlyFee = /* GraphQL */ `
  mutation CreateMivrMonthlyFee(
    $input: CreateMivrMonthlyFeeInput!
    $condition: ModelMivrMonthlyFeeConditionInput
  ) {
    createMivrMonthlyFee(input: $input, condition: $condition) {
      FacilityID
      Month
      DataType
      Received
      Completed
      Transferred
      CompleteRatio
      SMSCount
      TotalResponseTime
      AverageResponseTime
      ResponseFee
      SMSFee
      TotalFee
      SMSApiCount
      SMSApiFee
      SVCount
      SVFee
      KRCount
      KRFee
      WMCount
      WMFee
      IVCount
      IVFee
      AutoOutboundCount
      OutboundCount
    }
  }
`;
export const updateMivrMonthlyFee = /* GraphQL */ `
  mutation UpdateMivrMonthlyFee(
    $input: UpdateMivrMonthlyFeeInput!
    $condition: ModelMivrMonthlyFeeConditionInput
  ) {
    updateMivrMonthlyFee(input: $input, condition: $condition) {
      FacilityID
      Month
      DataType
      Received
      Completed
      Transferred
      CompleteRatio
      SMSCount
      TotalResponseTime
      AverageResponseTime
      ResponseFee
      SMSFee
      TotalFee
      SMSApiCount
      SMSApiFee
      SVCount
      SVFee
      KRCount
      KRFee
      WMCount
      WMFee
      IVCount
      IVFee
      AutoOutboundCount
      OutboundCount
    }
  }
`;
export const deleteMivrMonthlyFee = /* GraphQL */ `
  mutation DeleteMivrMonthlyFee(
    $input: DeleteMivrMonthlyFeeInput!
    $condition: ModelMivrMonthlyFeeConditionInput
  ) {
    deleteMivrMonthlyFee(input: $input, condition: $condition) {
      FacilityID
      Month
      DataType
      Received
      Completed
      Transferred
      CompleteRatio
      SMSCount
      TotalResponseTime
      AverageResponseTime
      ResponseFee
      SMSFee
      TotalFee
      SMSApiCount
      SMSApiFee
      SVCount
      SVFee
      KRCount
      KRFee
      WMCount
      WMFee
      IVCount
      IVFee
      AutoOutboundCount
      OutboundCount
    }
  }
`;
export const createMivrOperationLog = /* GraphQL */ `
  mutation CreateMivrOperationLog(
    $input: CreateMivrOperationLogInput!
    $condition: ModelMivrOperationLogConditionInput
  ) {
    createMivrOperationLog(input: $input, condition: $condition) {
      ID
      Timestamp
      LogLevel
      Component
      Function
      User
      Param
      UserAgent
      IP
      Version
    }
  }
`;
export const updateMivrOperationLog = /* GraphQL */ `
  mutation UpdateMivrOperationLog(
    $input: UpdateMivrOperationLogInput!
    $condition: ModelMivrOperationLogConditionInput
  ) {
    updateMivrOperationLog(input: $input, condition: $condition) {
      ID
      Timestamp
      LogLevel
      Component
      Function
      User
      Param
      UserAgent
      IP
      Version
    }
  }
`;
export const deleteMivrOperationLog = /* GraphQL */ `
  mutation DeleteMivrOperationLog(
    $input: DeleteMivrOperationLogInput!
    $condition: ModelMivrOperationLogConditionInput
  ) {
    deleteMivrOperationLog(input: $input, condition: $condition) {
      ID
      Timestamp
      LogLevel
      Component
      Function
      User
      Param
      UserAgent
      IP
      Version
    }
  }
`;
export const createMivrSequence = /* GraphQL */ `
  mutation CreateMivrSequence(
    $input: CreateMivrSequenceInput!
    $condition: ModelMivrSequenceConditionInput
  ) {
    createMivrSequence(input: $input, condition: $condition) {
      Name
      CurrentNumber
    }
  }
`;
export const updateMivrSequence = /* GraphQL */ `
  mutation UpdateMivrSequence(
    $input: UpdateMivrSequenceInput!
    $condition: ModelMivrSequenceConditionInput
  ) {
    updateMivrSequence(input: $input, condition: $condition) {
      Name
      CurrentNumber
    }
  }
`;
export const deleteMivrSequence = /* GraphQL */ `
  mutation DeleteMivrSequence(
    $input: DeleteMivrSequenceInput!
    $condition: ModelMivrSequenceConditionInput
  ) {
    deleteMivrSequence(input: $input, condition: $condition) {
      Name
      CurrentNumber
    }
  }
`;
export const createMivrSMSHistory = /* GraphQL */ `
  mutation CreateMivrSMSHistory(
    $input: CreateMivrSMSHistoryInput!
    $condition: ModelMivrSMSHistoryConditionInput
  ) {
    createMivrSMSHistory(input: $input, condition: $condition) {
      ID
      MessageId
      RequestId
      HospitalGuid
      PhoneNumber
      Text
      SmsCount
      BounceUrl
      RequestingService
      SmsStatus
      Timestamp
      DataType
      FacilityID
      IsSent
      Provider
      ShortMessageId
      FailureReason
      IsWebView
      UsePassword
      Title
      ShortenedMsg
      IsSentShortenMsg
      AuthAttemptCount
      LastAuthAttemptTime
      Url
    }
  }
`;
export const updateMivrSMSHistory = /* GraphQL */ `
  mutation UpdateMivrSMSHistory(
    $input: UpdateMivrSMSHistoryInput!
    $condition: ModelMivrSMSHistoryConditionInput
  ) {
    updateMivrSMSHistory(input: $input, condition: $condition) {
      ID
      MessageId
      RequestId
      HospitalGuid
      PhoneNumber
      Text
      SmsCount
      BounceUrl
      RequestingService
      SmsStatus
      Timestamp
      DataType
      FacilityID
      IsSent
      Provider
      ShortMessageId
      FailureReason
      IsWebView
      UsePassword
      Title
      ShortenedMsg
      IsSentShortenMsg
      AuthAttemptCount
      LastAuthAttemptTime
      Url
    }
  }
`;
export const deleteMivrSMSHistory = /* GraphQL */ `
  mutation DeleteMivrSMSHistory(
    $input: DeleteMivrSMSHistoryInput!
    $condition: ModelMivrSMSHistoryConditionInput
  ) {
    deleteMivrSMSHistory(input: $input, condition: $condition) {
      ID
      MessageId
      RequestId
      HospitalGuid
      PhoneNumber
      Text
      SmsCount
      BounceUrl
      RequestingService
      SmsStatus
      Timestamp
      DataType
      FacilityID
      IsSent
      Provider
      ShortMessageId
      FailureReason
      IsWebView
      UsePassword
      Title
      ShortenedMsg
      IsSentShortenMsg
      AuthAttemptCount
      LastAuthAttemptTime
      Url
    }
  }
`;
export const signUp = /* GraphQL */ `
  mutation SignUp(
    $action: String!
    $facilityId: Int!
    $facilityName: String!
    $userId: String!
    $userEmail: String!
  ) {
    signUp(
      action: $action
      facilityId: $facilityId
      facilityName: $facilityName
      userId: $userId
      userEmail: $userEmail
    ) {
      statusCode
      message
    }
  }
`;
